import React from 'react'
import { graphql } from "gatsby"
import HelpCenterLayout from '../components/helpCenterLayout/HelpCenterLayout'


const ShopperHelpCenter = ({data}) => {

    return (
        <div>
          <HelpCenterLayout data ={data} header='Shopper Help Center'/>
        </div>
    )
}

export default ShopperHelpCenter

export const query = graphql`
  query {
    allMdx(filter:{slug:{regex:"/shopper-hc/"}}) {
        edges{
         node{
          slug
          frontmatter{
              title
              category
          }
         }
       }
       }
    allMdx(filter:{slug:{regex:"/shopper-hc/"}}) {
        distinct(field:frontmatter___category)
        }
  }
`